// Todos componentes do primeng
.p-component {
  font-family: "Montserrat", sans-serif;
}

p-calendar {
  // todo: passar o estilo para todos os inputs
  .p-inputtext:enabled:focus {
    box-shadow: 0px 0px 2px 3.5px rgba(162, 168, 244, 0.82);
    border: 1px solid var(--primary-ymunity);
    border-radius: 0.125rem;
  }

  .p-datepicker {
    color: var(--grayscale-600);

    // hovers
    &:not(.p-disabled) {
      table td span:not(.p-highlight):not(.p-disabled):hover,
      .p-monthpicker
        .p-monthpicker-month:not(.p-disabled):not(.p-highlight):hover,
      .p-yearpicker
        .p-yearpicker-year:not(.p-disabled):not(.p-highlight):hover {
        background: var(--grayscale-surface-hover);
      }
    }

    // header
    .p-datepicker-header .p-datepicker-title {
      .p-datepicker-month,
      .p-datepicker-year {
        color: var(--grayscale-600);

        &:enabled:hover {
          color: var(--primary-hover);
        }
      }
    }

    // date
    table td {
      &.p-datepicker-today span {
        border: 1px solid var(--primary-ymunity);
        background: transparent;

        &.p-highlight {
          background: var(--primary-ymunity);
          color: var(--grayscale-surface-white);
        }
      }

      span {
        border-radius: 0.25rem;
        color: var(--grayscale-600);

        &:hover {
          background: var(--grayscale-surface-hover);
        }

        &.p-highlight {
          border: 1px solid transparent;
          background: var(--primary-ymunity);
          color: var(--grayscale-surface-white);
        }
      }
    }

    // month and year
    .p-monthpicker .p-monthpicker-month.p-highlight,
    .p-yearpicker .p-yearpicker-year.p-highlight {
      background: var(--primary-ymunity);
      color: var(--grayscale-surface-white);
    }
  }

  // footer buttons
  .p-button.p-button-text {
    color: var(--primary-ymunity);

    &:enabled:hover {
      background: var(--primary-light);
      color: var(--primary-ymunity);
    }
  }
}

// Input Text General
.p-inputtext {
  &:enabled:focus {
    box-shadow: 0 0 0 0.2rem var(--ymunity-border-shadow);
    border-color: var(--primary-bright);
  }

  &:enabled:hover {
    border-color: var(--primary-ymunity);
  }

  &:disabled {
    background: var(--grayscale-50);
    border-color: var(--grayscale-100);
    opacity: 1;
  }
}

.p-focus {
  box-shadow: 0px 0px 2px 3.5px var(--ymunity-border-shadow);
}

// multiselect
.p-multiselect {
  width: 100%;

  &:not(.p-disabled):hover {
    border-color: var(--primary-ymunity);
  }

  &.p-disabled {
    background: var(--grayscale-50);
    border-color: var(--grayscale-100);
    opacity: 1;
  }
}

.p-inputtext-sm {
  .p-multiselect .p-multiselect-label {
    font-size: 0.875rem;
    padding: 0.65625rem 0.65625rem;
  }
}

// Multiselect Input Text
.p-multiselect-panel {
  .p-multiselect-header {
    padding: 0.75rem 0.6rem;
    column-gap: 4px;
    background: var(--grayscale-surface-ground);
  }

  .p-multiselect-item {
    column-gap: 9px;

    &:not(.p-highlight):not(.p-disabled):hover {
      background: var(--grayscale-50);
    }

    &:focus {
      box-shadow: inset 0 0 0 0.15rem var(--primary-ymunity);
      border-color: var(--primary-bright);
    }

    &.p-highlight {
      color: var(--grayscale-600);
      background: var(--primary-light);
    }
  }
}

// Radio button General
.p-radiobutton {
  .p-radiobutton-box {
    &.p-highlight {
      border-color: var(--primary-bright);
      background: var(--primary-ymunity);
    }

    .p-radiobutton-icon {
      background-color: var(--primary-ymunity);
    }

    &:not(.p-disabled):not(.p-highlight):hover {
      border-color: var(--primary-ymunity);
    }
  }
}

// Checkbox General
.p-checkbox {
  .p-checkbox-box.p-highlight {
    border-color: var(--primary-ymunity);
    background: var(--primary-ymunity);
  }

  &:not(.p-checkbox-disabled) {
    .p-checkbox-box:hover {
      border-color: var(--primary-hover);
    }

    .p-checkbox-box.p-focus {
      border-color: var(--primary-hover);
    }
  }
}

p-tag {
  // base/default
  .p-tag {
    background: var(--primary-ymunity);
    color: var(--grayscale-surface-white);
    gap: 0.4rem;
    min-width: 75px;
    font-weight: 700;
    padding: 0.1rem 0.4rem;

    // sucess
    &.p-tag-success {
      background-color: var(--status-active_bg);
      color: var(--status-active);
    }

    // danger
    &.p-tag-danger {
      background-color: var(--status-inactive_bg);
      color: var(--status-inactive);
    }

    // info
    &.p-tag-info {
      background-color: var(--status-info_bg);
      color: var(--status-info);
    }

    // warning
    &.p-tag-warning {
      background-color: var(--status-warning_bg);
      color: var(--status-warning);
    }

    // neutral
    &.p-tag-neutral {
      background-color: var(--grayscale-50);
      color: var(--primary-neutro);
    }

    &.p-tag-not-contemplated {
      background-color: var(--grayscale-100);
      color: var(--primary-neutro);
    }

    &.p-tag-canceled {
      background-color: transparent;
      color: var(--grayscale-800);
    }

    // crm
    &.p-tag-crm {
      background-color: var(--primary-light);
      color: var(--primary-sidebar);
      font-size: 14px;
      font-weight: 500;
    }

    &.p-tag-treatment,
    &.p-tag-treatment-waiting,
    &.p-tag-treatment-ongoing,
    &.p-tag-treatment-paused,
    &.p-tag-treatment-discontinued {
      min-width: 0;
      gap: 0;
      padding: 4px;
    }

    &.p-tag-treatment-waiting {
      background-color: var(--primary-ymunity);
      color: var(--grayscale-surface-white);
    }

    &.p-tag-treatment-ongoing {
      background-color: var(--status-active_bg);
      color: var(--system-success-900);
    }

    &.p-tag-treatment-paused {
      background-color: var(--system-warning-background);
      color: var(--system-warning-700);
    }

    &.p-tag-treatment-discontinued {
      background-color: var(--system-warning-background);
      color: var(--system-danger-700);
    }
  }
}

// badge

// base/default
.p-badge {
  font-family: "Inter var", sans-serif;
  font-size: 10.5px;
  border-radius: 12px;
  padding: 0 0.3rem;
  min-width: 21px;
  height: 21px;
  line-height: 21px;

  i {
    font-size: 10.5px;
  }

  &.p-badge-lg {
    font-size: 15.75px;
    line-height: 31.5px;
    min-width: 31.5px;
    height: 31.5px;
    border-radius: 15.75px;

    i {
      font-size: 14px;
    }
  }

  &.p-badge-xl {
    font-size: 21px;
    line-height: 42px;
    min-width: 42px;
    height: 42px;
    border-radius: 21px;

    i {
      font-size: 17px;
    }
  }
}

// badge on bottom - usage: iconPos="bottom"
[badgePos="bottom"] {
  span {
    top: unset;
    bottom: 0;
    transform: translate(50%, 50%);
  }
}

// badge inside button
.p-button {
  .p-badge {
    height: unset;
    line-height: unset;
  }
}

// Tabmenu
.p-tabmenu {
  .p-tabmenuitem {
    &.p-highlight {
      .p-menuitem-link {
        border-color: var(--primary-ymunity);
        color: var(--primary-ymunity);
      }
    }

    .p-menuitem-link {
      background: transparent;
      border-color: transparent;
      color: var(--grayscale-400);
    }
  }

  .p-tabmenu-nav {
    background: none;
    border-color: transparent;
  }

  .p-tabmenu-nav-btn {
    &.p-link {
      color: var(--primary-ymunity);
    }
  }
}

p-dialog {
  .p-dialog {
    width: 90%;
    max-width: 470px;
  }
}

// button
.pi.p-button-icon-left {
  margin-right: 0.5rem;
}

.pi.p-button-icon-right {
  margin-left: 0.5rem;
}

// tooltip
.p-tooltip {
  font-size: 12px;

  .p-tooltip-text {
    background: var(--primary-neutro);
    text-align: center;
  }

  &.p-tooltip-bottom .p-tooltip-arrow {
    border-bottom-color: var(--primary-neutro);
  }

  &.p-tooltip-top .p-tooltip-arrow {
    border-top-color: var(--primary-neutro);
  }

  &.p-tooltip-left .p-tooltip-arrow {
    border-left-color: var(--primary-neutro);
  }

  &.p-tooltip-right .p-tooltip-arrow {
    border-right-color: var(--primary-neutro);
  }
}

// input text label
.p-float-label {
  label {
    font-size: 14px;
  }

  input:focus,
  .p-inputwrapper-focus {
    & ~ label {
      font-size: 12px;
    }
  }
}

p-accordiontab .p-accordion-tab {
  margin-bottom: 16px;

  .p-accordion-header-link {
    font-family: Overpass, sans-serif;
    font-size: 14px;
    font-weight: 400;
    border: none;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    background: transparent;
    color: var(--grayscale-900);
    padding: 16px 22px;
    align-items: end;

    .p-icon-wrapper {
      width: 12px;
    }
  }

  .p-toggleable-content {
    font-size: 14px;
    color: var(--grayscale-900);
    margin: 0 5px;

    .p-accordion-content {
      border: none;
      background: transparent;
      padding: 16px 22px;
    }
  }
}

p-inputswitch {
  .p-inputswitch {
    width: 45px;
    height: 24.5px;

    &.p-focus {
      box-shadow: none;

      .p-inputswitch-slider {
        box-shadow: none;
      }
    }

    &.p-inputswitch-checked .p-inputswitch-slider {
      background: var(--primary-ymunity);
    }

    .p-inputswitch-slider:before {
      width: 17px;
      height: 17px;
      margin-top: -8px;
    }
  }
}

p-dropdown.ng-invalid.ng-dirty > .p-dropdown {
  border-color: #f44336;
}

.p-button.p-inputnumber-button-up {
  background: transparent;
  color: var(--grayscale-600);
  border-color: var(--grayscale-100);
  
  &:enabled:hover {
    background-color: var(--primary-ymunity);;
    color: #fff;
  }
}

.p-button.p-inputnumber-button-down {
  background: transparent;
  color: var(--grayscale-600);
  border-color: var(--grayscale-100);

  &:enabled:hover {
    background-color: var(--primary-ymunity);
    color: #fff;
  }
}

.p-togglebutton.p-button.p-highlight {
  background: var(--primary-ymunity);
  border-color: var(--primary-ymunity);

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--primary-ymunity), 0 1px 2px 0 var(--primary-ymunity);
  }
}

.p-togglebutton.p-button:not(.p-disabled):not(.p-highlight):hover {
  box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--primary-ymunity), 0 1px 2px 0 var(--primary-ymunity);
}

// CLASS YM-MENU COMPONENT
// THIS CLASS WAS OVERRIDED TO APPLY STYLES THAT WERE MISSING IN THE PROTOTYPE
.ym-menu-override-prime {
  .p-menuitem-link {
    padding: 12px 16px;
    font-size: 14px;
  }
}